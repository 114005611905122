
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import variables from "@/router/api";

export default defineComponent({
  name: "policy-receipts-table",
  components: {},
  props: {
    widgetClasses: String,
    receipts: Array
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    capitalize(value) {
      return variables.capitalize(value);
    }
  }
});
